






import Resource from './Resource.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { Resource },
});
