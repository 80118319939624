



























































import { defineComponent, toRefs, watch, PropType } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getStudyResources, getStudyResourceCount } from './api';
import VClamp from 'vue-clamp';

export default defineComponent({
  components: { VClamp },
  props: {
    type: {
      type: String as PropType<'study-resource' | 'meeting'>,
      default: 'study-resource',
    },
  },
  setup(props, { root }) {
    const { state } = useMainStore();
    const { result: resources, refresh } = useApi((page?: number, size?: number) =>
      getStudyResources(page || 1, size || 10, props.type),
    );

    const { result: total } = useApi(() => getStudyResourceCount(props.type));

    return {
      resources,
      ...toRefs(state),
      total,
      onPageChange: (page: number) => {
        refresh(page, 10);
      },
      toDetail: (id: number) => {
        if (props.type === 'study-resource') {
          root.$router.push({ name: 'StudyResourceDetail', params: { id: id.toString() } });
        } else {
          root.$router.push({ name: 'MeetingDetail', params: { id: id.toString() } });
        }
      },
    };
  },
});
